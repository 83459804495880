export const INTERNAL_SERVER_ERROR_CODE_ONE = "50001";
export const INTERNAL_SERVER_ERROR_CODE_TWO = "50002";

export interface ServiceAccessInfo {
  adminType: string;
  serviceName: string;
  operations: string[];
}

export interface UserSessionInfo {
  responseCode: number;
  message: string;
  servicesAccessInfoList: ServiceAccessInfo[];
  userId: string;
  csrfToken: string;
}

export interface SearchAdminGroupResponse {
  groupId: string;
  responseCode: number;
  message: string;
}

export interface CreateSiteAdminResponse {
  responseCode: number;
  message: string;
}

export interface SessionDetails {
  userSessionInfo: UserSessionInfo;
  csrfToken: string;
}

export interface GetBadgeDetailsResponse {
  cardId?: string,
  badgeStatus?: string | null,
  isLoanerBadge?: boolean | null ,
  badgeCreatedAt?: string | null,
  badgeRegisteredAt?: string | null,
  badgeManagedBy?: string | null,
  userId?: string,
  userIdentitySource?: string,
  userStatus?: string | null,
  employeeStatus?: string | null,
  employeeLocationId?: string | null,
  userCreatedAt?: string | null,
  userLastLoginAt?: string | null,
  success: boolean,
  responseMessage: string
}

export interface GetAssociateDetailsResponse {
    userId?: string,
    userIdentitySource?: string,
    userStatus?: string | null,
    employeeStatus?: string | null,
    employeeLocationId?: string | null,
    userCreatedAt?: string | null,
    userLastLoginAt?: string | null,
    success: boolean,
}

export const REGISTERED_BADGE_FOUND = "Registered_Badge_Found";

export interface Exception {
  errorId: string;
  message: string;
}

export const RECOGNIZED_RESPONSE_STATUS = [200, 400, 401, 403, 500];

export const HOME_PAGE_URL: string = "/services";

export const ALLOWED_SPECIAL_CHARACTERS_REGEX = "^[a-zA-Z0-9@._]+$";
export const REG_EXP = new RegExp(ALLOWED_SPECIAL_CHARACTERS_REGEX);

export const CREATE_NEW_TEAM_URL = "https://permissions.amazon.com/a/team/new";

export const EMPTY_USER_SESSION_INFO: UserSessionInfo = {
  responseCode: 0,
  message: "",
  servicesAccessInfoList: [],
  userId: "",
  csrfToken: "",
};

export const EMPTY_SESSION_DETAILS: SessionDetails = {
  userSessionInfo: EMPTY_USER_SESSION_INFO,
  csrfToken: "",
};

/**
 * Source from PeopleBus employee event filters
 * https://w.amazon.com/bin/view/AmazonPeopleEngine/Morag/Services/PB/Filtering/#HUDM-PeopleBusv2Filters
 */
export const PEOPLE_BUS_EMPLOYEE_STATUS_FILTER = new Map<string, string>([
    ["A", "Active"],
    ["D", "Deceased"],
    ["L", "Leave"],
    ["P", "Leave w/Pay"],
    ["Q", "Retired w/Pay"],
    ["R", "Retired"],
    ["S", "Suspended"],
    ["T", "Terminated"],
    ["U", "Term w/Pay"],
    ["V", "Term w/Pension"],
    ["W", "Short Work Break"],
    ["X", "Retired Pension Administration"],
]);
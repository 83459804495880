import {Link, Outlet} from "react-router-dom";
import Breadcrumbs from "../component/common/Breadcrumb";
import HeaderLayout from "./HeaderLayout";
import {Box, SpaceBetween} from "@amzn/awsui-components-react";
import {
  EMPTY_USER_SESSION_INFO,
  UserSessionInfo,
} from "../module/AuthAdminModule";
import {currentApiDomain, EMPTY_STRING} from "../util/helperUtil";
import * as React from "react";
import {useEffect} from "react";
import ResponsePage, {
  ResponsePageType,
} from "../component/common/ResponsePage";
import {Trans} from "react-i18next";
import Loading from "../component/common/Loading";
import {setLocale} from "../localization/LocalizationModule";
import {useTranslator} from "../localization/Translator";

export default function RootLayout() {
  const [csrfToken, setCsrfToken] = React.useState(EMPTY_STRING);
  const [userSessionInfo, setUserSessionInfo] = React.useState(
    EMPTY_USER_SESSION_INFO
  );
  const [isError, setIsError] = React.useState(false);
  const t = useTranslator();
  const AUTHORIZE_URL = `${currentApiDomain}/Authorize`;

  useEffect(() => {
    setDefaultLocale();
    const abortController = new AbortController();
    fetch(currentApiDomain + `/GetUserSessionInfo`, {
      method: "get",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "anti-csrftoken-a2z-request": "true",
      },
    })
      .then((response) => {
        // On random usage of UI Uri's without appropriate authorization on a browser , UI redirects the user to /Authorize endpoint on API Domain.
        if (response.status === 401) {
          window.location.replace(currentApiDomain + "/Authorize");
          return;
        }
        if (response.status !== 200) {
          setIsError(true);
        }
        setCsrfToken(response.headers.get("anti-csrftoken-a2z") || "");
        return response.json();
      })
      .then((res: UserSessionInfo) => {
        if (!res) return;
        if (
          res.servicesAccessInfoList === null ||
          res.servicesAccessInfoList.length === 0
        ) {
          window.location.replace(window.origin + "/notAuthorized");
          return;
        }
        setUserSessionInfo(res);
      })
      .catch((error: any) => {
        console.error("Error", error);
        if (error.name === "AbortError") return;
      });
    return function cancel() {
      abortController.abort();
    };
  }, []);

  const setDefaultLocale = () => {
    const queryParams = new URLSearchParams(window.location.search);
    setLocale(queryParams.get("ui_locales"));
  };

  const isLoading =
    userSessionInfo?.responseCode !== 200 ||
    userSessionInfo?.servicesAccessInfoList?.length === 0 ||
    !csrfToken;

  const unexpectedErrorMessage = (
    <Trans>
      {t.translate("un_expected_error_try_again")}
      <Link to={AUTHORIZE_URL}>{t.translate("form_field_link_text")}</Link>
    </Trans>
  );

  const errorPage = (
    <Box margin={{top: "xxxl"}}>
      <ResponsePage
        message={unexpectedErrorMessage}
        headerTitle={t.translate("unexpected_error_title")}
        pageType={ResponsePageType.ERROR}
      />
    </Box>
  );

  const successPage = isLoading ? (
    <Loading/>
  ) : (
    <div>
      <header>
        <SpaceBetween size={"xxl"}>
          <HeaderLayout userId={userSessionInfo?.userId}/>
          <Box margin={{left: "xxl"}}>
            <Breadcrumbs/>
          </Box>
        </SpaceBetween>
      </header>
      <main>
        <Box margin={{top: "xxxl"}}>
          <Outlet
            context={{csrfToken: csrfToken, userSessionInfo: userSessionInfo}}
          />
        </Box>
      </main>
    </div>
  );

  return isError ? errorPage : successPage;
}

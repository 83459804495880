import "./App.css";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Service from "./component/service/Service";
import RootLayout from "./layout/RootLayout";
import Operation from "./component/operation/Operation";
import CreateSiteAdmin from "./component/operation/create-site-admin/CreateSiteAdmin";
import Logout from "./layout/LogoutLayout";
import NotAuthorizedLayout from "./layout/NotAuthorizedLayout";
import NotFoundLayout from './layout/NotFoundLayout';
import CheckBadgeStatus from "./component/operation/check-badge-status/CheckBadgeStatus";
import PinReset from "./component/operation/pin-reset/PinReset";
import { setLocale } from "./localization/LocalizationModule";
const setDefaultLocale = () => {
    const queryParams = new URLSearchParams(window.location.search);
    setLocale(queryParams.get("ui_locales"));
}
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
        <Route index element={<Navigate to="services" />} />
        {/*  /!*We decided to remove landing page, currently commenting the below line, we could end up using in future releases based on requirements*!/*/}
        {/*<Route index element={<HomeComponent/>}/>*/}
        <Route path="services">
            <Route element={<RootLayout />}>
                <Route index element={<Service />} />
                <Route path="name_maask/operations">
                    <Route index element={<Operation />} />
                    <Route path="createLocationAdmin">
                        <Route index element={<CreateSiteAdmin />} />
                    </Route>
                    <Route path="pinReset">
                        <Route index element={<PinReset />} />
                    </Route>
                    <Route path="badgeStatus">
                        <Route index element={<CheckBadgeStatus />} />
                    </Route>
                </Route>
            </Route>
        </Route>

        <Route path="logout" element={<Logout />} />
        <Route path="notAuthorized" element={<NotAuthorizedLayout />} />
        <Route path="*" element={<NotFoundLayout />} />
    </Route>
  )
);

function App() {
  setDefaultLocale()
  return <RouterProvider router={router} />;
}

export default App;
